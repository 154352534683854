<template>
  <div class="d-flex flex-column">
    <v-card class="mb-2">
      <v-card-title>
        <v-checkbox
          hide-details
          v-model="computedInvoiceOn"
          :label="$t('t.Invoices')"
          color="secondary"
          class="mb-2"
        />
      </v-card-title>
      <v-card-text>
        <transition name="fade">
          <div v-show="computedInvoiceOn">
            <div class="d-flex flex-row gap">
              <filter-params
                v-model="computedInvoiceFilter"
                :readonly="readonly"
                auto-filter-type="invoices"
                :label="$t('t.Invoices')"
              />
              <invoice-balance
                :document.sync="computedInvoiceBalance"
                :readonly="readonly"
              />
            </div>
          </div>
        </transition>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-title>
        <v-checkbox
          hide-details
          v-model="computedUnappliedCashOn"
          :label="$t('t.UnappliedCashes')"
          color="secondary"
          class="mb-2"
        />
      </v-card-title>
      <v-card-text>

        <transition name="fade">
          <div v-show="computedUnappliedCashOn">
            <filter-params
              v-model="computedUnappliedCashFilter"
              :readonly="readonly"
              auto-filter-type="unapplied-cashes"
              :label="$t('t.UnappliedCashes')"
            />
          </div>
        </transition>
      </v-card-text>
    </v-card>

    <v-card class="mb-2">
      <v-card-title>
        <v-checkbox
          hide-details
          v-model="computedWorkInProgressOn"
          :label="$t('t.WorkInProgressItems')"
          color="secondary"
          class="mb-2"
        />
      </v-card-title>
      <v-card-text>
        <transition name="fade">
          <div v-show="computedWorkInProgressOn">
            <filter-params
              v-model="computedWorkInProgressFilter"
              :readonly="readonly"
              auto-filter-type="work-in-progress-items"
              :label="$t('t.WorkInProgressItems')"
            />
          </div>
        </transition>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    FilterParams: () => import('./filter-params/filter-params'),
    InvoiceBalance: () => import('./invoice-balance')
  },
  computed: {
    computedInvoiceOn: {
      get () {
        return this.isInvoiceOn
      },
      set (v) {
        this.isInvoiceOn = v
        this.dataDocumentEmit()
      }
    },
    computedUnappliedCashOn: {
      get () {
        return this.isUnappliedCashOn
      },
      set (v) {
        this.isUnappliedCashOn = v
        this.dataDocumentEmit()
      }
    },
    computedWorkInProgressOn: {
      get () {
        return this.isWorkInProgressOn
      },
      set (v) {
        this.isWorkInProgressOn = v
        this.dataDocumentEmit()
      }
    },
    computedInvoiceFilter: {
      get () { return this.dataInvoiceFilter },
      set (v) {
        this.dataInvoiceFilter = v
        this.dataDocumentEmit()
      }
    },
    computedWorkInProgressFilter: {
      get () { return this.dataWorkInProgressFilter },
      set (v) {
        this.dataWorkInProgressFilter = v
        this.dataDocumentEmit()
      }
    },
    computedUnappliedCashFilter: {
      get () { return this.dataUnappliedCashFilter },
      set (v) {
        this.dataUnappliedCashFilter = v
        this.dataDocumentEmit()
      }
    },
    computedInvoiceBalance: {
      get () { return this.dataInvoiceBalance },
      set (v) {
        this.dataInvoiceBalance = Object.assign({}, this.dataInvoiceBalance, v)
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = {}
      if (this.isInvoiceOn) {
        this.$set(doc, 'invoices', { filter: this.dataInvoiceFilter, fees: this.dataInvoiceBalance?.fees, paymentFilter: this.dataInvoiceBalance?.paymentFilter })
      } else {
        this.$set(doc, 'invoices', undefined)
      }

      if (this.isUnappliedCashOn) {
        this.$set(doc, 'unappliedCashes', { filter: this.dataUnappliedCashFilter })
      } else {
        this.$set(doc, 'unappliedCashes', undefined)
      }

      if (this.isWorkInProgressOn) {
        this.$set(doc, 'workInProgresses', { filter: this.dataWorkInProgressFilter })
      } else {
        this.$set(doc, 'workInProgresses', undefined)
      }
      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      isInvoiceOn: true,
      isUnappliedCashOn: false,
      isWorkInProgressOn: false,
      dataInvoiceFilter: {},
      dataWorkInProgressFilter: {},
      dataUnappliedCashFilter: {},
      dataInvoiceBalance: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    document: Object,
    readonly: Boolean,
    targetDocTy: String
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        const a = this.lodash.cloneDeep(v)
        this.isInvoiceOn = !!a?.invoices
        this.isUnappliedCashOn = !!a?.unappliedCashes
        this.isWorkInProgressOn = !!a?.workInProgresses
        this.dataInvoiceFilter = a?.invoices?.filter ?? {}
        this.dataInvoiceBalance = a?.invoices ? { fees: a.invoices.fees, paymentFilter: a.invoices.paymentFilter } : undefined
        this.dataUnappliedCashFilter = a?.unappliedCashes?.filter ?? {}
        this.dataWorkInProgressFilter = a?.workInProgresses?.filter ?? {}
      }
    }
  }
}
</script>
